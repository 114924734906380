import { MatchList } from "../../api/CompetitionList.js";
export default {
    name: "CompetitionList",
    data() {
        return {
            MatchListData: [],
            Params: {
                keywords: "",
                pageNum: 1,
                pageSize: 10,
            },
            Total: 0,
        };
    },
    created() {},
    mounted() {
        this.getdata();
    },
    methods: {
        getdata() {
            MatchList(this.Params).then((res) => {
                this.MatchListData = res.data.list;
                this.Total = Number(res.data.total);
            });
        },
        // 指定项目跳转
        ProjectJump(item) {
            // if (item.challenge_status == 1) {
            //   this.$message.error("当前比赛已结算，请下次开放时参加！");
            //   return;
            // }
            this.$router.push({
                path: "/SingleItem",
                query: {
                    id: item.challenge_id,
                    matchstate: item.challenge_status,
                },
            });
        },
        // 计算比赛状态
        CalculateGameStatus(
            registration_time,
            start_time,
            en_time,
            challenge_status
        ) {
            if (challenge_status == "-1") {
                return "已暂停";
            } else if (challenge_status == "1") {
                return "已结束";
            } else if (challenge_status == "0") {
                if (
                    new Date(this.getNowDate()) > new Date(registration_time) &&
                    new Date(this.getNowDate()) < new Date(start_time)
                ) {
                    return "报名中";
                } else if (
                    new Date(this.getNowDate()) > new Date(start_time) &&
                    new Date(this.getNowDate()) < new Date(en_time)
                ) {
                    return "进行中";
                } else {
                    return "进行中";
                }
            }
        },
        // 格式化日对象
        getNowDate() {
            var date = new Date();
            var sign2 = ":";
            var year = date.getFullYear(); // 年
            var month = date.getMonth() + 1; // 月
            var day = date.getDate(); // 日
            var hour = date.getHours(); // 时
            var minutes = date.getMinutes(); // 分
            var seconds = date.getSeconds(); //秒
            // 给一位数的数据前面加 “0”
            if (month >= 1 && month <= 9) {
                month = "0" + month;
            }
            if (day >= 0 && day <= 9) {
                day = "0" + day;
            }
            if (hour >= 0 && hour <= 9) {
                hour = "0" + hour;
            }
            if (minutes >= 0 && minutes <= 9) {
                minutes = "0" + minutes;
            }
            if (seconds >= 0 && seconds <= 9) {
                seconds = "0" + seconds;
            }
            return (
                year +
                "-" +
                month +
                "-" +
                day +
                " " +
                hour +
                sign2 +
                minutes +
                sign2 +
                seconds
            );
        },
    },
};
